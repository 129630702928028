<template>
  <svg :width="size" :height="size" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M39.5 40C40.3284 40 41 40.6716 41 41.5V43.5C41 44.3284 40.3284 45 39.5 45C38.6716 45 38 44.3284 38 43.5V41.5C38 40.6716 38.6716 40 39.5 40Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M41 41.5C41 40.6716 40.3284 40 39.5 40C38.6716 40 38 40.6716 38 41.5V43.5C38 44.3284 38.6716 45 39.5 45C40.3284 45 41 44.3284 41 43.5V41.5Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M49.5095 36.457C49.0781 35.6103 48.3897 34.9219 47.543 34.4905C47.0416 34.235 46.5229 34.1218 46 34.0654V32.5C46 28.9101 43.0899 26 39.5 26C35.9101 26 33 28.9101 33 32.5V34.0654C32.4771 34.1218 31.9584 34.235 31.457 34.4905C30.6103 34.9219 29.9219 35.6103 29.4905 36.457C29.2013 37.0246 29.0943 37.6144 29.0459 38.2075C28.9999 38.7693 29 39.4515 29 40.2422V44.7578C29 45.5485 28.9999 46.2307 29.0459 46.7925C29.0943 47.3856 29.2013 47.9754 29.4905 48.543C29.9219 49.3897 30.6103 50.0781 31.457 50.5095C32.0246 50.7987 32.6144 50.9057 33.2075 50.9541C33.7693 51 34.4515 51 35.2422 51H43.7578C44.5485 51 45.2307 51 45.7925 50.9541C46.3856 50.9057 46.9754 50.7987 47.543 50.5095C48.3897 50.0781 49.0781 49.3897 49.5095 48.543C49.7987 47.9754 49.9057 47.3856 49.9541 46.7925C50.0001 46.2307 50 45.5486 50 44.7578V40.2421C50 39.4514 50.0001 38.7693 49.9541 38.2075C49.9057 37.6144 49.7987 37.0246 49.5095 36.457ZM33.4518 37.0359C33.0443 37.0692 32.8932 37.1257 32.819 37.1635C32.5368 37.3073 32.3073 37.5368 32.1635 37.819C32.1257 37.8932 32.0692 38.0443 32.0359 38.4518C32.0012 38.8768 32 39.4352 32 40.3V44.7C32 45.5648 32.0012 46.1232 32.0359 46.5482C32.0692 46.9557 32.1257 47.1068 32.1635 47.181C32.3073 47.4632 32.5368 47.6927 32.819 47.8365C32.8932 47.8743 33.0443 47.9308 33.4518 47.9641C33.8768 47.9988 34.4352 48 35.3 48H43.7C44.5648 48 45.1232 47.9988 45.5482 47.9641C45.9557 47.9308 46.1068 47.8743 46.181 47.8365C46.4632 47.6927 46.6927 47.4632 46.8365 47.181C46.8743 47.1068 46.9308 46.9557 46.9641 46.5482C46.9988 46.1232 47 45.5648 47 44.7V40.3C47 39.4352 46.9988 38.8768 46.9641 38.4518C46.9308 38.0443 46.8743 37.8932 46.8365 37.819C46.6927 37.5368 46.4632 37.3073 46.181 37.1635C46.1068 37.1257 45.9557 37.0692 45.5482 37.0359C45.1232 37.0012 44.5648 37 43.7 37H35.3C34.4352 37 33.8768 37.0012 33.4518 37.0359ZM36 32.5C36 30.567 37.567 29 39.5 29C41.433 29 43 30.567 43 32.5V34H36V32.5Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M28 5C22.2009 5 16.8826 5.91462 12.959 7.44578C11.0019 8.20953 9.31596 9.15601 8.09383 10.2836C6.87017 11.4126 6 12.8359 6 14.5V39.5C6 40.9902 6.70057 42.2925 7.72485 43.3557C8.74229 44.4118 10.1474 45.3102 11.7795 46.0562C15.0487 47.5503 19.5079 48.5594 24.4763 48.8856C25.303 48.9399 26.0171 48.3137 26.0714 47.4871C26.1256 46.6604 25.4995 45.9463 24.6728 45.892C19.9501 45.582 15.872 44.6281 13.0266 43.3276C11.6015 42.6763 10.5551 41.9695 9.8853 41.2742C9.22235 40.5861 9 39.9912 9 39.5V31.9596C9.86703 32.5959 10.8856 33.1619 12.0074 33.6586C15.4031 35.1623 20.0298 36.1569 25.1644 36.4262C25.9917 36.4696 26.6975 35.8341 26.7409 35.0069C26.7843 34.1796 26.1488 33.4737 25.3216 33.4303C20.4381 33.1742 16.1933 32.2313 13.2221 30.9156C11.7335 30.2564 10.6355 29.5348 9.93105 28.8212C9.23266 28.1137 9 27.5019 9 27V19.4595C10.099 20.2662 11.4482 20.9646 12.959 21.5542C16.8826 23.0854 22.2009 24 28 24C33.7991 24 39.1174 23.0854 43.041 21.5542C44.5518 20.9646 45.901 20.2662 47 19.4595V26.1758C47 27.0042 47.6716 27.6758 48.5 27.6758C49.3284 27.6758 50 27.0042 50 26.1758V14.5C50 12.8359 49.1298 11.4126 47.9062 10.2836C46.684 9.15601 44.9981 8.20953 43.041 7.44578C39.1174 5.91462 33.7991 5 28 5ZM10.1282 12.4885C9.2771 13.2737 9 13.955 9 14.5C9 15.045 9.2771 15.7263 10.1282 16.5115C10.9807 17.2981 12.297 18.0755 14.0496 18.7595C17.5456 20.1238 22.4772 21 28 21C33.5228 21 38.4544 20.1238 41.9504 18.7595C43.703 18.0755 45.0193 17.2981 45.8718 16.5115C46.7229 15.7263 47 15.045 47 14.5C47 13.955 46.7229 13.2737 45.8718 12.4885C45.0193 11.7019 43.703 10.9245 41.9504 10.2405C38.4544 8.87624 33.5228 8 28 8C22.4772 8 17.5456 8.87624 14.0496 10.2405C12.297 10.9245 10.9807 11.7019 10.1282 12.4885Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'DataLockIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>