<template>
  <svg :width="size" :height="size" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.46966 3.46967C5.76255 3.17678 6.23743 3.17678 6.53032 3.46967L12.5303 9.46967C12.8232 9.76256 12.8232 10.2374 12.5303 10.5303C12.2374 10.8232 11.7626 10.8232 11.4697 10.5303L5.46966 4.53033C5.17677 4.23744 5.17677 3.76256 5.46966 3.46967Z" fill="currentColor"/>
    <path d="M13.75 4.75C14.4403 4.05964 14.4403 2.94036 13.75 2.25C13.0596 1.55964 11.9403 1.55964 11.25 2.25L7.99999 5.5L10.5 8L13.75 4.75Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7197 1.71967C11.7029 0.736419 13.2971 0.736421 14.2803 1.71967C15.2636 2.70292 15.2636 4.29708 14.2803 5.28033L6.85449 12.7062C5.88275 13.6779 4.63166 14.3217 3.2761 14.5477C2.20348 14.7264 1.27356 13.7965 1.45233 12.7239C1.67826 11.3683 2.32209 10.1172 3.29383 9.1455L10.7197 1.71967ZM13.2197 2.78033C12.8222 2.38287 12.1778 2.38287 11.7803 2.78033L9.06065 5.5L10.5 6.93934L13.2197 4.21967C13.6171 3.82221 13.6171 3.17779 13.2197 2.78033ZM9.43933 8L7.99999 6.56066L4.35449 10.2062C3.60381 10.9568 3.10645 11.9233 2.93192 12.9705C2.92236 13.0279 2.97211 13.0776 3.02951 13.0681C4.07668 12.8935 5.04315 12.3962 5.79383 11.6455L9.43933 8Z" fill="currentColor"/>
  </svg>

</template>

<script>
export default {
  name: 'ColorPicker',
  props: {
    size: {
      type: Number,
      default: 16
    }
  }
}
</script>