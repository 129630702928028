<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.5 4C23.433 4 25 5.567 25 7.5V9.5C25 10.3284 24.3284 11 23.5 11H23V20C23 22.2091 21.2091 24 19 24H9C6.79086 24 5 22.2091 5 20V11H4.5C3.67157 11 3 10.3284 3 9.5V7.5C3 5.567 4.567 4 6.5 4H21.5ZM21 11H7V20C7 21.1046 7.89543 22 9 22H19C20.1046 22 21 21.1046 21 20V11ZM17 13.5C17.5523 13.5 18 13.9477 18 14.5C18 15.0523 17.5523 15.5 17 15.5H11C10.4477 15.5 10 15.0523 10 14.5C10 13.9477 10.4477 13.5 11 13.5H17ZM21.5 6H6.5C5.67157 6 5 6.67157 5 7.5V9H23V7.5C23 6.67157 22.3284 6 21.5 6Z" fill="currentColor"/>
  </svg>

</template>

<script>
export default {
  name: 'ArchiveIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>