<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 2C20.6274 2 26 7.37258 26 14C26 20.6274 20.6274 26 14 26C7.37258 26 2 20.6274 2 14C2 7.37258 7.37258 2 14 2ZM14 4C8.47715 4 4 8.47715 4 14C4 19.5228 8.47715 24 14 24C19.5228 24 24 19.5228 24 14C24 8.47715 19.5228 4 14 4ZM14 17.6C14.7732 17.6 15.4 18.2268 15.4 19C15.4 19.7732 14.7732 20.4 14 20.4C13.2268 20.4 12.6 19.7732 12.6 19C12.6 18.2268 13.2268 17.6 14 17.6ZM14.0736 8C14.3112 8 14.4604 8.03713 14.5907 8.10685C14.7211 8.17658 14.8234 8.27889 14.8931 8.40926C14.9629 8.53963 15 8.68878 15 8.92638V15.0736C15 15.3112 14.9629 15.4604 14.8931 15.5907C14.8234 15.7211 14.7211 15.8234 14.5907 15.8931C14.4604 15.9629 14.3112 16 14.0736 16H13.9264C13.6888 16 13.5396 15.9629 13.4093 15.8931C13.2789 15.8234 13.1766 15.7211 13.1069 15.5907C13.0371 15.4604 13 15.3112 13 15.0736V8.92638C13 8.68878 13.0371 8.53963 13.1069 8.40926C13.1766 8.27889 13.2789 8.17658 13.4093 8.10685C13.5396 8.03713 13.6888 8 13.9264 8H14.0736Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'ErrorIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>