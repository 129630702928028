<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 18.5858L5.70711 13.2929C5.31658 12.9024 4.68342 12.9024 4.29289 13.2929C3.90237 13.6834 3.90237 14.3166 4.29289 14.7071L10.2929 20.7071C10.6834 21.0976 11.3166 21.0976 11.7071 20.7071L23.7071 8.70711C24.0976 8.31658 24.0976 7.68342 23.7071 7.29289C23.3166 6.90237 22.6834 6.90237 22.2929 7.29289L11 18.5858Z" fill="currentColor"/>
  </svg>

</template>

<script>
export default {
  name: 'DoneIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>