<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.79925 11.3629C5.14303 9.68416 4.68306 8.38603 4.47909 7.5563C4.22753 6.53294 3.80476 5.39985 4.22702 4.36803C4.48361 3.74102 5.02366 3.23551 5.7485 3.06245C6.69852 2.83563 7.59566 3.28467 8.411 3.7215C10.189 4.67414 19.4871 9.53746 21.7317 10.7183C23.0425 11.408 24.9288 12.1464 24.9981 13.88C25.0652 15.5576 23.442 16.3118 22.1823 16.9745C17.5791 19.3959 13.0044 21.8724 8.38737 24.2673C7.54804 24.7026 6.62369 25.1767 5.65175 24.9309C4.85624 24.7298 4.32401 24.1392 4.11309 23.4818C3.92625 22.8994 4.00601 22.3001 4.14535 21.7191C4.55954 19.9921 5.13786 18.3039 5.77964 16.6497C6.20917 15.5426 6.97181 14.6276 7.93045 13.9949C6.98248 13.3651 6.22759 12.4586 5.79925 11.3629ZM12.2164 13.2525C13.2409 13.4589 13.7581 13.5702 13.8667 13.7485C13.9644 13.9091 13.9637 14.1115 13.8649 14.2714C13.7498 14.4576 13.1859 14.5672 12.058 14.7862C11.2804 14.9372 10.6421 15.0604 10.4457 15.0966C9.163 15.3326 8.10109 16.1955 7.64422 17.3731C7.09782 18.7815 6.61316 20.1891 6.22801 21.649C5.83905 23.1234 5.77403 23.3698 7.46647 22.4919C12.1402 20.0676 16.7736 17.5652 21.431 15.1096C22.4672 14.5631 22.9972 14.2701 22.9999 13.9723C23.0026 13.6773 22.4878 13.3776 21.4355 12.8222C16.7842 10.3671 12.1025 7.9683 7.46647 5.48442C5.80099 4.59209 5.87828 4.90168 6.3199 6.67063C6.65865 8.0275 7.15318 9.33312 7.66199 10.6347C8.12045 11.8075 9.1795 12.6665 10.458 12.9025C11.0457 13.011 11.6305 13.1344 12.2164 13.2525Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'SendIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>