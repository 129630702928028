<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 2.33331C20.4284 2.33331 25.6667 7.04665 25.6667 12.8333C25.6667 16.695 22.5284 19.8333 18.6667 19.8333H16.6017C16.275 19.8333 16.0184 20.09 16.0184 20.4166C16.0184 20.5566 16.0767 20.685 16.17 20.8016C16.6484 21.35 16.9167 22.0383 16.9167 22.75C16.9167 24.36 15.61 25.6666 14 25.6666C7.57171 25.6666 2.33337 20.4283 2.33337 14C2.33337 7.57165 7.57171 2.33331 14 2.33331ZM13.995 4.32998C8.66721 4.32998 4.33004 8.66715 4.33004 13.995C4.33004 19.3228 8.66721 23.66 13.995 23.66C14.3333 23.66 14.5991 23.3942 14.5991 23.0559C14.5991 22.8626 14.5025 22.7176 14.43 22.6331C13.9346 22.0773 13.6688 21.3645 13.6688 20.6397C13.6688 18.9725 15.0219 17.6194 16.6892 17.6194H18.8275C21.4975 17.6194 23.66 15.4568 23.66 12.7869C23.66 8.12349 19.3229 4.32998 13.995 4.32998ZM7.58337 11.6666C8.54987 11.6666 9.33337 12.4501 9.33337 13.4166C9.33337 14.3831 8.54987 15.1666 7.58337 15.1666C6.61688 15.1666 5.83337 14.3831 5.83337 13.4166C5.83337 12.4501 6.61688 11.6666 7.58337 11.6666ZM20.4167 11.6666C21.3832 11.6666 22.1667 12.4501 22.1667 13.4166C22.1667 14.3831 21.3832 15.1666 20.4167 15.1666C19.4502 15.1666 18.6667 14.3831 18.6667 13.4166C18.6667 12.4501 19.4502 11.6666 20.4167 11.6666ZM11.0834 6.99998C12.0499 6.99998 12.8334 7.78348 12.8334 8.74998C12.8334 9.71648 12.0499 10.5 11.0834 10.5C10.1169 10.5 9.33337 9.71648 9.33337 8.74998C9.33337 7.78348 10.1169 6.99998 11.0834 6.99998ZM16.9167 6.99998C17.8832 6.99998 18.6667 7.78348 18.6667 8.74998C18.6667 9.71648 17.8832 10.5 16.9167 10.5C15.9502 10.5 15.1667 9.71648 15.1667 8.74998C15.1667 7.78348 15.9502 6.99998 16.9167 6.99998Z" fill="currentColor"/>
  </svg>

</template>

<script>
export default {
  name: 'PaletteIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>