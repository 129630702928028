<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 15C12.9973 15 16.5 16.5424 16.5 19.9286C16.5 21.7035 15.8109 22.5 14.3529 22.5H3.64706C2.18907 22.5 1.5 21.7035 1.5 19.9286C1.5 16.5424 5.00271 15 9 15ZM19.5 15C23.4973 15 27 16.5424 27 19.9286C27 21.7035 26.3109 22.5 24.8529 22.5H19C18.4477 22.5 18 22.0523 18 21.5C18 20.9477 18.4477 20.5 19 20.5L24.9128 20.5003C24.9298 20.5005 24.9439 20.5009 24.9554 20.5017L24.968 20.503L24.9704 20.4882C24.9809 20.407 24.9945 20.2672 24.9987 20.0606L25 19.9286C25 18.0954 22.5125 17 19.5 17C19.0723 17 18.6018 17.03 18.1302 17.0909C17.5825 17.1615 17.0812 16.7748 17.0105 16.227C16.9398 15.6793 17.3266 15.178 17.8743 15.1073C18.431 15.0355 18.987 15 19.5 15ZM9 17C5.98749 17 3.5 18.0954 3.5 19.9286C3.5 20.2089 3.51695 20.3908 3.52958 20.4882L3.531 20.503L3.54456 20.5017L3.56385 20.5008L14.4288 20.5005C14.4389 20.5008 14.4477 20.5012 14.4554 20.5017L14.468 20.503L14.4704 20.4882C14.483 20.3908 14.5 20.2089 14.5 19.9286C14.5 18.0954 12.0125 17 9 17ZM19.5 5C21.8479 5 23.75 6.90209 23.75 9.25C23.75 11.5979 21.8479 13.5 19.5 13.5C17.1521 13.5 15.25 11.5979 15.25 9.25C15.25 6.90209 17.1521 5 19.5 5ZM9 5C11.3479 5 13.25 6.90209 13.25 9.25C13.25 11.5979 11.3479 13.5 9 13.5C6.65209 13.5 4.75 11.5979 4.75 9.25C4.75 6.90209 6.65209 5 9 5ZM19.5 7C18.2567 7 17.25 8.00666 17.25 9.25C17.25 10.4933 18.2567 11.5 19.5 11.5C20.7433 11.5 21.75 10.4933 21.75 9.25C21.75 8.00666 20.7433 7 19.5 7ZM9 7C7.75666 7 6.75 8.00666 6.75 9.25C6.75 10.4933 7.75666 11.5 9 11.5C10.2433 11.5 11.25 10.4933 11.25 9.25C11.25 8.00666 10.2433 7 9 7Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'UsersIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>