<template>
  <div class="d-flex justify-center align-center mt-8">
    <v-progress-circular
        indeterminate
        color="smoke"
        width="2"
        class="mt-4"
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  components: {

  },
  data: () => ({

  }),
  methods: {

  },
}
</script>