<template>
  <v-container fluid style="padding-bottom: 100px">
    <slot></slot>
  </v-container>
</template>

<script>
export default {
  name: 'Container',
  components: {

  },
  props: {

  },
  data: () => ({

  }),
  methods: {

  },
}
</script>