<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.9601 3.26713C18.6341 3.09283 18.3109 3 17.4193 3H10.5471C9.65559 3 9.3323 3.09283 9.00638 3.26713C8.68045 3.44144 8.42466 3.69723 8.25036 4.02315C8.11905 4.26868 8.03398 4.51271 8 4.99928H19.9664C19.9325 4.51271 19.8474 4.26868 19.7161 4.02315C19.5418 3.69723 19.286 3.44144 18.9601 3.26713ZM19.1765 7.00178L9.12777 7C7.35117 7 6.48019 7.16819 5.57471 7.65245C4.74859 8.09427 4.09427 8.74859 3.65245 9.57471C3.19668 10.4269 3.02088 11.2486 3.00178 12.8235L3 17.8722C3 19.6488 3.16819 20.5198 3.65245 21.4253C4.09427 22.2514 4.74859 22.9057 5.57471 23.3475C6.48019 23.8318 7.35117 24 9.12777 24H18.8722C20.6488 24 21.5198 23.8318 22.4253 23.3475C23.2514 22.9057 23.9057 22.2514 24.3475 21.4253C24.8318 20.5198 25 19.6488 25 17.8722V13.1278C25 11.3512 24.8318 10.4802 24.3475 9.57471C23.9057 8.74859 23.2514 8.09427 22.4253 7.65245C21.5731 7.19668 20.7514 7.02088 19.1765 7.00178ZM16.8284 16.5858L22.6872 10.728C22.9166 11.2428 23 11.8316 23 13.1278V17.8722L22.9954 18.3562C22.9718 19.4787 22.8558 19.9737 22.5839 20.4821C22.3285 20.9597 21.9597 21.3285 21.4821 21.5839C20.906 21.892 20.3469 22 18.8722 22H9.12777L8.6438 21.9954C7.52132 21.9718 7.02626 21.8558 6.51791 21.5839C6.04032 21.3285 5.67149 20.9597 5.41608 20.4821C5.10796 19.906 5 19.3469 5 17.8722V13.1278C5 11.8316 5.08341 11.2428 5.31283 10.728L11.1716 16.5858L11.3309 16.7368C12.9014 18.1461 15.3184 18.0958 16.8284 16.5858ZM9.12777 9H18.8722C20.1684 9 20.7572 9.08341 21.272 9.31283L15.4142 15.1716L15.287 15.2884C14.5012 15.9505 13.3257 15.9115 12.5858 15.1716L6.72802 9.31283C7.24279 9.08341 7.8316 9 9.12777 9Z" fill="currentColor"/>
  </svg>

</template>

<script>
export default {
  name: 'MailStackIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>