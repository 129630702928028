<template>
  <svg :width="size" :height="size" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M25.9642 4H19.0358C17.4101 3.99999 16.0989 3.99997 15.0371 4.08673C13.9438 4.17605 12.9835 4.36477 12.0951 4.81745C10.6839 5.5365 9.5365 6.68386 8.81745 8.09507C8.36477 8.98351 8.17605 9.94379 8.08673 11.0371C7.99997 12.0989 7.99999 13.4101 8 15.0358V40.9642C7.99999 42.5899 7.99997 43.9011 8.08673 44.9629C8.17605 46.0562 8.36477 47.0165 8.81745 47.9049C9.5365 49.3161 10.6839 50.4635 12.0951 51.1825C12.9835 51.6352 13.9438 51.8239 15.0371 51.9133C16.0989 52 17.4101 52 19.0358 52H27.5C28.3284 52 29 51.3284 29 50.5C29 49.6716 28.3284 49 27.5 49H19.1C17.3951 49 16.2066 48.9988 15.2814 48.9232C14.3736 48.8491 13.8521 48.7108 13.457 48.5095C12.6103 48.0781 11.9219 47.3897 11.4905 46.543C11.2892 46.1479 11.1509 45.6264 11.0768 44.7186C11.0012 43.7934 11 42.6049 11 40.9V15.1C11 13.3951 11.0012 12.2066 11.0768 11.2814C11.1509 10.3736 11.2892 9.85208 11.4905 9.45704C11.9219 8.61031 12.6103 7.9219 13.457 7.49047C13.8521 7.28919 14.3736 7.15093 15.2814 7.07676C16.2066 7.00117 17.3951 7 19.1 7H25.9C27.6049 7 28.7934 7.00117 29.7186 7.07676C30.6264 7.15093 31.1479 7.28919 31.543 7.49047C32.3897 7.9219 33.0781 8.61031 33.5095 9.45704C33.7108 9.85208 33.8491 10.3736 33.9232 11.2814C33.9988 12.2066 34 13.3951 34 15.1V19.5C34 20.3284 34.6716 21 35.5 21C36.3284 21 37 20.3284 37 19.5V15.0358C37 13.4101 37 12.0989 36.9133 11.0371C36.8239 9.94379 36.6352 8.98351 36.1825 8.09507C35.4635 6.68386 34.3161 5.5365 32.9049 4.81745C32.0165 4.36477 31.0562 4.17605 29.9629 4.08673C28.9011 3.99997 27.5899 3.99999 25.9642 4Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M35.5 48C42.1274 48 47.5 42.6274 47.5 36C47.5 29.3726 42.1274 24 35.5 24C28.8726 24 23.5 29.3726 23.5 36C23.5 42.6274 28.8726 48 35.5 48ZM35.5 28C34.6716 28 34 28.6716 34 29.5V36.5C34 37.3284 34.6716 38 35.5 38C36.3284 38 37 37.3284 37 36.5V29.5C37 28.6716 36.3284 28 35.5 28ZM33.5 42C33.5 40.8954 34.3954 40 35.5 40C36.6046 40 37.5 40.8954 37.5 42C37.5 43.1046 36.6046 44 35.5 44C34.3954 44 33.5 43.1046 33.5 42Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'AlertDeviceBigIcon',
  props: {
    size: {
      type: Number,
      default: 56
    }
  }
}
</script>