<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.8145 4C21.0191 4 21.6185 4.09559 22.2635 4.38076C22.8674 4.64779 23.3726 5.05305 23.7643 5.58467C24.1826 6.15242 24.4059 6.71676 24.6672 7.89269L25.9762 13.7831L26 14V17.8722C26 19.6488 25.8318 20.5198 25.3475 21.4253C24.9057 22.2514 24.2514 22.9057 23.4253 23.3475C22.5198 23.8318 21.6488 24 19.8722 24H8.12777C6.35117 24 5.48019 23.8318 4.57471 23.3475C3.74859 22.9057 3.09427 22.2514 2.65245 21.4253C2.16819 20.5198 2 19.6488 2 17.8722V14L2.02381 13.7831L3.33279 7.89269C3.5941 6.71676 3.81744 6.15242 4.23573 5.58467C4.62741 5.05305 5.13261 4.64779 5.73654 4.38076C6.38151 4.09559 6.98086 4 8.18547 4H19.8145ZM4 17.8722C4 19.3469 4.10796 19.906 4.41608 20.4821C4.67149 20.9597 5.04032 21.3285 5.51791 21.5839C6.09404 21.892 6.6531 22 8.12777 22H19.8722C21.3469 22 21.906 21.892 22.4821 21.5839C22.9597 21.3285 23.3285 20.9597 23.5839 20.4821C23.892 19.906 24 19.3469 24 17.8722V15H18.9C18.4367 17.2822 16.419 19 14 19C11.581 19 9.56329 17.2822 9.10002 15H4V17.8722ZM20.0231 6.00098L8.18547 6C7.23589 6 6.89839 6.05383 6.54531 6.20994C6.25993 6.33612 6.03098 6.51978 5.8459 6.77099C5.61692 7.08179 5.49115 7.39958 5.28516 8.32655L4.246 13H10C10.5128 13 10.9355 13.386 10.9933 13.8834L11 14C11 15.6569 12.3431 17 14 17C15.6569 17 17 15.6569 17 14C17 13.4477 17.4477 13 18 13H23.753L22.7148 8.32655C22.5088 7.39958 22.3831 7.08179 22.1541 6.77099C21.969 6.51978 21.7401 6.33612 21.4547 6.20994C21.1288 6.06584 20.8161 6.00889 20.0231 6.00098Z" fill="currentColor"/>
  </svg>

</template>

<script>
export default {
  name: 'InboxIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>