<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 22C21.5523 22 22 22.4477 22 23C22 23.5523 21.5523 24 21 24H7C6.44772 24 6 23.5523 6 23C6 22.4477 6.44772 22 7 22H21ZM14 3L14.0194 3.00019C14.0427 3.00063 14.066 3.00189 14.0893 3.00397L14 3C14.0506 3 14.1004 3.00376 14.149 3.01102C14.1659 3.01363 14.1834 3.0167 14.2007 3.02024C14.2227 3.02464 14.2444 3.02983 14.2658 3.03572C14.2815 3.0401 14.2969 3.04473 14.3121 3.04974C14.3318 3.05612 14.3516 3.06332 14.3711 3.0711C14.3888 3.0782 14.4061 3.08568 14.4232 3.09367C14.4439 3.10338 14.4643 3.11379 14.4843 3.12488C14.4964 3.1315 14.5086 3.13861 14.5207 3.146C14.5463 3.16172 14.5711 3.17849 14.5952 3.19631C14.6343 3.22531 14.6717 3.2575 14.7071 3.29289L14.6167 3.21279C14.6197 3.21507 14.6226 3.21737 14.6254 3.21969L14.7071 3.29289L21.7071 10.2929C22.0976 10.6834 22.0976 11.3166 21.7071 11.7071C21.3166 12.0976 20.6834 12.0976 20.2929 11.7071L15 6.414V19C15 19.5523 14.5523 20 14 20C13.4477 20 13 19.5523 13 19V6.414L7.70711 11.7071C7.34662 12.0676 6.77939 12.0953 6.3871 11.7903L6.29289 11.7071C5.90237 11.3166 5.90237 10.6834 6.29289 10.2929L13.2929 3.29289L13.3746 3.21969C13.3774 3.21737 13.3803 3.21507 13.3833 3.21279C13.3902 3.20728 13.3974 3.20175 13.4047 3.19633C13.4289 3.17849 13.4537 3.16172 13.4793 3.14607C13.4914 3.13861 13.5036 3.1315 13.5159 3.12467C13.5357 3.11379 13.5561 3.10338 13.5769 3.09365C13.5939 3.08568 13.6112 3.0782 13.6287 3.07123C13.6484 3.06332 13.6682 3.05612 13.6883 3.04955C13.7031 3.04473 13.7185 3.0401 13.734 3.03585C13.7556 3.02983 13.7773 3.02464 13.7992 3.02017C13.8166 3.0167 13.8341 3.01363 13.8515 3.01102C13.8705 3.00811 13.8902 3.00577 13.91 3.004C13.9343 3.00187 13.958 3.0006 13.9816 3.00017C13.9872 3.00006 13.9936 3 14 3Z" fill="currentColor"/>
  </svg>

</template>

<script>
export default {
  name: 'UploadIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>