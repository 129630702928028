<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 15.9142L11.7071 17.2071C11.3166 17.5976 10.6834 17.5976 10.2929 17.2071C9.90237 16.8166 9.90237 16.1834 10.2929 15.7929L13.2929 12.7929C13.6834 12.4024 14.3166 12.4024 14.7071 12.7929L17.7071 15.7929C18.0976 16.1834 18.0976 16.8166 17.7071 17.2071C17.3166 17.5976 16.6834 17.5976 16.2929 17.2071L15 15.9142V19.5C15 20.0523 14.5523 20.5 14 20.5C13.4477 20.5 13 20.0523 13 19.5V15.9142Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 7.5C3 5.567 4.567 4 6.5 4H21.5C23.433 4 25 5.567 25 7.5V9.5C25 10.3284 24.3284 11 23.5 11H23V18.2413C23 19.0463 23 19.7106 22.9558 20.2518C22.9099 20.8139 22.8113 21.3306 22.564 21.816C22.1805 22.5686 21.5686 23.1805 20.816 23.564C20.3306 23.8113 19.8139 23.9099 19.2518 23.9558C18.7106 24 18.0463 24 17.2413 24H10.7587C9.95374 24 9.28937 24 8.74817 23.9558C8.18608 23.9099 7.66937 23.8113 7.18404 23.564C6.43139 23.1805 5.81947 22.5686 5.43597 21.816C5.18868 21.3306 5.09012 20.8139 5.04419 20.2518C4.99998 19.7106 4.99999 19.0463 5 18.2413L5 11H4.5C3.67157 11 3 10.3284 3 9.5V7.5ZM7 11H21V18.2C21 19.0566 20.9992 19.6389 20.9624 20.089C20.9266 20.5274 20.8617 20.7516 20.782 20.908C20.5903 21.2843 20.2843 21.5903 19.908 21.782C19.7516 21.8617 19.5274 21.9266 19.089 21.9624C18.6389 21.9992 18.0566 22 17.2 22H10.8C9.94342 22 9.36113 21.9992 8.91104 21.9624C8.47262 21.9266 8.24842 21.8617 8.09202 21.782C7.71569 21.5903 7.40973 21.2843 7.21799 20.908C7.1383 20.7516 7.07337 20.5274 7.03755 20.089C7.00078 19.6389 7 19.0566 7 18.2V11ZM23 7.5V9H5V7.5C5 6.67157 5.67157 6 6.5 6H21.5C22.3284 6 23 6.67157 23 7.5Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'UnarchiveIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>