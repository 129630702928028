<template>
  <svg :width="size" :height="size" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 6C0 9.31385 2.68615 12 6 12C9.31292 12 12 9.31385 12 6C12 2.68615 9.31292 0 6 0C2.68615 0 0 2.68615 0 6ZM6.40319 3.00005C6.40319 2.59124 6.08907 2.30005 5.70159 2.30005C5.31411 2.30005 5 2.61345 5 3.00005L5.00731 6.60104C5.04587 6.86572 5.23397 7.08917 5.49596 7.16931L8.20323 7.98944C8.56246 8.09395 8.87505 7.83831 8.97829 7.50229C9.06281 7.13254 8.89901 6.74588 8.51315 6.62786L6.40319 5.98205V3.00005Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'ClockIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>