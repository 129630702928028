<template>
  <svg :width="size" :height="size" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.04631 2.53427C4.78763 2.1378 5.51749 2 7.12777 2H12.8722C13.2864 2 13.6222 2.33579 13.6222 2.75C13.6222 3.16421 13.2864 3.5 12.8722 3.5H7.12777C5.59677 3.5 5.16644 3.63625 4.75371 3.85699C4.36519 4.06477 4.06477 4.36519 3.85699 4.75371C3.63625 5.16644 3.5 5.59677 3.5 7.12777V12.8722C3.5 14.4032 3.63625 14.8336 3.85699 15.2463C4.06477 15.6348 4.36519 15.9352 4.75371 16.143C5.16644 16.3637 5.59677 16.5 7.12777 16.5H12.8722C14.4032 16.5 14.8336 16.3637 15.2463 16.143C15.6348 15.9352 15.9352 15.6348 16.143 15.2463C16.3637 14.8336 16.5 14.4032 16.5 12.8722V7.12777C16.5 6.71356 16.8358 6.37777 17.25 6.37777C17.6642 6.37777 18 6.71356 18 7.12777V12.8722C18 14.4825 17.8622 15.2124 17.4657 15.9537C17.1182 16.6036 16.6036 17.1182 15.9537 17.4657C15.2124 17.8622 14.4825 18 12.8722 18H7.12777C5.51749 18 4.78763 17.8622 4.04631 17.4657C3.39639 17.1182 2.88185 16.6036 2.53427 15.9537C2.1378 15.2124 2 14.4825 2 12.8722V7.12777C2 5.51749 2.1378 4.78763 2.53427 4.04631C2.88185 3.39639 3.39639 2.88185 4.04631 2.53427Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5266 3.47335C16.8245 3.77115 16.8245 4.25398 16.5266 4.55179L10.1704 10.9081C9.88826 11.1902 9.05119 11.6441 8.42093 11.9648C8.16627 12.0944 7.90557 11.8337 8.03516 11.5791C8.35588 10.9488 8.80983 10.1118 9.09192 9.82967L15.4482 3.47335C15.746 3.17555 16.2288 3.17555 16.5266 3.47335Z" fill="currentColor"/>
    <path d="M18.5 2.25C18.5 2.66421 18.1642 3 17.75 3C17.3358 3 17 2.66421 17 2.25C17 1.83579 17.3358 1.5 17.75 1.5C18.1642 1.5 18.5 1.83579 18.5 2.25Z" fill="currentColor"/>
  </svg>

</template>

<script>
export default {
  name: 'EditMiniIcon',
  props: {
    size: {
      type: Number,
      default: 20
    }
  }
}
</script>