<template>
  <svg :width="size" :height="size" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.9961 15.2573C12.868 11.2275 9.56092 8 5.5 8C4.67157 8 4 8.67157 4 9.5C4 10.3284 4.67157 11 5.5 11C7.98528 11 10 13.0147 10 15.5V40.5L9.9951 40.7118C9.88449 43.0988 7.91427 45 5.5 45C4.67157 45 4 45.6716 4 46.5C4 47.3284 4.67157 48 5.5 48C9.64214 48 13 44.6421 13 40.5V15.5L12.9961 15.2573ZM20.8621 8.97868C22.2203 8.25229 23.5268 8 26.1917 8L30.1484 8.00145C32.5869 8.02283 33.8388 8.28387 35.1379 8.97868C36.3771 9.6414 37.3586 10.6229 38.0213 11.8621C38.6845 13.1022 38.9525 14.2992 38.9941 16.5264L39 17.1917V38.8083C39 41.4732 38.7477 42.7797 38.0213 44.1379C37.3586 45.3771 36.3771 46.3586 35.1379 47.0213C33.8978 47.6845 32.7008 47.9525 30.4736 47.9941L29.8083 48H26.1917C23.5268 48 22.2203 47.7477 20.8621 47.0213C19.6229 46.3586 18.6414 45.3771 17.9787 44.1379C17.2523 42.7797 17 41.4732 17 38.8083L17.0014 16.8516C17.0228 14.4131 17.2839 13.1612 17.9787 11.8621C18.6414 10.6229 19.6229 9.6414 20.8621 8.97868ZM29.8083 11L25.8984 11.001C23.9034 11.0153 23.1018 11.1829 22.2769 11.6241C21.5605 12.0072 21.0072 12.5605 20.6241 13.2769C20.1619 14.1411 20 14.9797 20 17.1917V38.8083C20 41.0203 20.1619 41.8589 20.6241 42.7231C21.0072 43.4395 21.5605 43.9928 22.2769 44.3759C23.0625 44.796 23.827 44.9681 25.6214 44.9959L26.1917 45H29.8083C32.0203 45 32.8589 44.8381 33.7231 44.3759C34.4395 43.9928 34.9928 43.4395 35.3759 42.7231C35.796 41.9375 35.9681 41.173 35.9959 39.3786L36 38.8083V17.1917C36 14.9797 35.8381 14.1411 35.3759 13.2769C34.9928 12.5605 34.4395 12.0072 33.7231 11.6241C32.8589 11.1619 32.0203 11 29.8083 11ZM50.5 8C46.4391 8 43.132 11.2275 43.0039 15.2573L43 15.5V40.5C43 44.6421 46.3579 48 50.5 48C51.3284 48 52 47.3284 52 46.5C52 45.6716 51.3284 45 50.5 45C48.0857 45 46.1155 43.0988 46.0049 40.7118L46 40.5V15.5C46 13.0147 48.0147 11 50.5 11C51.3284 11 52 10.3284 52 9.5C52 8.67157 51.3284 8 50.5 8Z" fill="currentColor"/>
  </svg>

</template>

<script>
export default {
  name: 'FragmentIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>