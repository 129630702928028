<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.0858 14L10.2929 19.7929C9.90237 20.1834 9.90237 20.8166 10.2929 21.2071C10.6834 21.5976 11.3166 21.5976 11.7071 21.2071L18.2071 14.7071C18.5976 14.3166 18.5976 13.6834 18.2071 13.2929L11.7071 6.79289C11.3166 6.40237 10.6834 6.40237 10.2929 6.79289C9.90237 7.18342 9.90237 7.81658 10.2929 8.20711L16.0858 14Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'ChevronRightIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>