<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 2C15 1.44772 14.5523 1 14 1C13.4477 1 13 1.44772 13 2V5C13 5.55228 13.4477 6 14 6C14.5523 6 15 5.55228 15 5V2Z" fill="currentColor"/>
    <path d="M15 23C15 22.4477 14.5523 22 14 22C13.4477 22 13 22.4477 13 23V26C13 26.5523 13.4477 27 14 27C14.5523 27 15 26.5523 15 26V23Z" fill="currentColor"/>
    <path d="M23.8923 20.866C24.3706 21.1422 24.9822 20.9783 25.2583 20.5C25.5345 20.0217 25.3706 19.4101 24.8923 19.134L22.2942 17.634C21.816 17.3578 21.2044 17.5217 20.9282 18C20.6521 18.4783 20.816 19.0899 21.2942 19.366L23.8923 20.866Z" fill="currentColor"/>
    <path d="M5.70579 10.366C6.18408 10.6422 6.79567 10.4783 7.07182 10C7.34796 9.5217 7.18408 8.91011 6.70579 8.63397L4.10771 7.13397C3.62942 6.85783 3.01783 7.02171 2.74169 7.5C2.46555 7.97829 2.62942 8.58988 3.10771 8.86602L5.70579 10.366Z" fill="currentColor"/>
    <path d="M2.74169 20.5C2.46554 20.0217 2.62942 19.4101 3.10771 19.134L5.70579 17.634C6.18408 17.3578 6.79567 17.5217 7.07181 18C7.34795 18.4783 7.18408 19.0899 6.70579 19.366L4.10771 20.866C3.62942 21.1422 3.01783 20.9783 2.74169 20.5Z" fill="currentColor"/>
    <path d="M20.9282 10C20.6521 9.52171 20.816 8.91012 21.2942 8.63398L23.8923 7.13398C24.3706 6.85783 24.9822 7.02171 25.2583 7.5C25.5345 7.97829 25.3706 8.58989 24.8923 8.86603L22.2942 10.366C21.816 10.6422 21.2044 10.4783 20.9282 10Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14 8.03125C10.7036 8.03125 8.03125 10.7036 8.03125 14C8.03125 17.2964 10.7036 19.9688 14 19.9688C17.2964 19.9688 19.9688 17.2964 19.9688 14C19.9688 10.7036 17.2964 8.03125 14 8.03125ZM9.99219 14C9.99219 11.6454 11.6454 9.99219 14 9.99219C16.3546 9.99219 18.0156 11.6454 18.0156 14C18.0156 16.3546 16.3546 18 14 18C11.6454 18 9.99219 16.3546 9.99219 14Z" fill="currentColor"/>
  </svg>

</template>

<script>
export default {
  name: 'SunIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>