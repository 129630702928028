<template>
  <svg :width="size" :height="size" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9999 18C10.9999 13.5817 14.5817 10 18.9999 10C23.4182 10 26.9999 13.5817 26.9999 18V27C26.9999 31.4183 23.4182 35 18.9999 35C14.5817 35 10.9999 31.4183 10.9999 27V18ZM23.9999 18V27C23.9999 29.7614 21.7614 32 18.9999 32C16.2385 32 13.9999 29.7614 13.9999 27V18C13.9999 15.2386 16.2385 13 18.9999 13C21.7614 13 23.9999 15.2386 23.9999 18Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M32.5 21H34.8722C36.9507 21 38.1336 21.2284 39.3684 21.8888C40.5431 22.517 41.4829 23.4568 42.1111 24.6315C42.4204 25.2098 42.6349 25.7766 42.7756 26.4294L45.422 24.9649C47.1914 23.9854 49.4625 24.5526 50.4947 26.2318C50.8256 26.7701 50.9999 27.3822 50.9999 28.0054V35.9945C50.9999 37.9386 49.3393 39.5145 47.2909 39.5145C46.6341 39.5145 45.9892 39.3491 45.422 39.035L42.7756 37.5705C42.6349 38.2234 42.4204 38.7902 42.1111 39.3685C41.4829 40.5432 40.5431 41.483 39.3684 42.1112C38.1336 42.7716 36.9507 43 34.8722 43H32.4999C31.6715 43 31 42.3284 31 41.5C31 40.6716 31.6716 40 32.5 40L32.5108 40H34.8722C36.6552 40 37.3018 39.8144 37.9536 39.4658C38.6055 39.1172 39.1171 38.6056 39.4657 37.9537C39.8143 37.3019 39.9999 36.6553 39.9999 34.8723V29.1277C39.9999 27.3447 39.8143 26.6981 39.4657 26.0463C39.1171 25.3944 38.6055 24.8829 37.9536 24.5343C37.3018 24.1856 36.6552 24 34.8722 24H32.5054L32.5 24C31.6716 24 31 23.3284 31 22.5C31 21.6716 31.6716 21 32.5 21ZM43.0705 34.305L42.9999 34.2642V29.7358L43.0705 29.695L46.8746 27.5898C47.0617 27.4862 47.2888 27.4572 47.5079 27.5119C47.7265 27.5665 47.8661 27.6843 47.9389 27.8028C47.9836 27.8755 47.9999 27.9449 47.9999 28.0054V35.9945C47.9999 36.0859 47.966 36.1959 47.8485 36.3075C47.7281 36.4217 47.5376 36.5145 47.2909 36.5145C47.1356 36.5145 46.9916 36.4749 46.8749 36.4104L43.0705 34.305Z" fill="currentColor"/>
    <path d="M6.31112 31.8583C7.04703 31.4779 7.95201 31.7661 8.33243 32.502C10.3293 36.3649 14.3582 39 18.9999 39C23.6417 39 27.6706 36.3649 29.6675 32.502C30.0479 31.7661 30.9529 31.4779 31.6888 31.8583C32.4247 32.2387 32.7129 33.1437 32.3324 33.8796C30.0579 38.2796 25.6653 41.413 20.4999 41.9259V45C20.4999 45.8284 19.8284 46.5 18.9999 46.5C18.1715 46.5 17.4999 45.8284 17.4999 45V41.9259C12.3346 41.413 7.942 38.2796 5.66746 33.8796C5.28703 33.1437 5.57521 32.2387 6.31112 31.8583Z" fill="currentColor"/>
  </svg>

</template>

<script>
export default {
  name: 'RtcBigIcon',
  props: {
    size: {
      type: Number,
      default: 56
    }
  }
}
</script>