<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9442 13.3019L15.1206 1.96596C14.9158 0.692983 13.0842 0.692988 12.8794 1.96597L11.0559 13.3019C11.0371 13.4186 11.0254 13.5345 11.0203 13.6493C11.0069 13.7644 11 13.8814 11 14C11 15.6568 12.3431 17 14 17C15.6569 17 17 15.6568 17 14C17 13.8818 16.9932 13.7652 16.9799 13.6506C16.9748 13.5353 16.963 13.419 16.9442 13.3019ZM14.9998 14C14.9998 14.5522 14.5521 15 13.9998 15C13.4475 15 12.9998 14.5522 12.9998 14C12.9998 13.4477 13.4475 13 13.9998 13C14.5521 13 14.9998 13.4477 14.9998 14Z" fill="currentColor"/>
    <path d="M17.431 3.49757C17.6496 2.99037 18.2379 2.75639 18.7451 2.97495C20.1519 3.58119 21.4188 4.44827 22.4853 5.51469C24.6557 7.68517 26 10.6865 26 14C26 16.4133 25.2865 18.6631 24.0587 20.5462C23.7571 21.0088 23.1375 21.1393 22.6749 20.8376C22.2122 20.536 22.0817 19.9164 22.3834 19.4538C23.4058 17.8858 24 16.0138 24 14C24 11.2383 22.8818 8.73965 21.071 6.92891C20.1811 6.03892 19.1248 5.31634 17.9536 4.81167C17.4464 4.59311 17.2124 4.00476 17.431 3.49757Z" fill="currentColor"/>
    <path d="M10.5632 3.49944C10.782 4.00651 10.5484 4.59499 10.0413 4.81383C9.99721 4.83286 9.95328 4.8522 9.90951 4.87185C8.79288 5.37308 7.78412 6.0737 6.92893 6.92889C5.11819 8.73963 4 11.2383 4 14C4 16.0138 4.59422 17.8857 5.6166 19.4538C5.91825 19.9164 5.78774 20.536 5.3251 20.8376C4.86247 21.1393 4.2429 21.0088 3.94126 20.5461C2.71346 18.6631 2 16.4133 2 14C2 10.6865 3.34424 7.68515 5.51472 5.51468C6.53946 4.48994 7.74927 3.64928 9.09049 3.04724C9.14306 3.02364 9.19583 3.00041 9.2488 2.97755C9.75587 2.75871 10.3443 2.99236 10.5632 3.49944Z" fill="currentColor"/>
  </svg>

</template>

<script>
export default {
  name: 'DashboardIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>