<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.4142 14L18.2071 8.20711C18.5976 7.81658 18.5976 7.18342 18.2071 6.79289C17.8166 6.40237 17.1834 6.40237 16.7929 6.79289L10.2929 13.2929C9.90237 13.6834 9.90237 14.3166 10.2929 14.7071L16.7929 21.2071C17.1834 21.5976 17.8166 21.5976 18.2071 21.2071C18.5976 20.8166 18.5976 20.1834 18.2071 19.7929L12.4142 14Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'ChevronLeftIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>