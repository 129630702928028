<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.1662 15.6852C24.9586 15.4046 25.7226 16.1675 25.4431 16.9603C24.8744 18.5739 23.9978 20.0062 22.8112 21.1886C18.393 25.6068 11.2319 25.6061 6.81447 21.1888C2.39521 16.7721 2.39521 9.60928 6.81425 5.1906C7.99871 4.00611 9.43138 3.12887 11.0417 2.55923C11.8346 2.27873 12.5985 3.04281 12.3179 3.83566C11.1241 7.20821 11.9241 10.9693 14.4782 13.5252C17.0322 16.0787 20.7953 16.879 24.1662 15.6852ZM13.064 14.9394C10.4879 12.3616 9.37918 8.777 9.90121 5.29579C9.29147 5.66527 8.73163 6.10164 8.22845 6.60483C4.59055 10.2424 4.59055 16.1386 8.22846 19.7744C11.8651 23.4109 17.7599 23.4115 21.3982 19.7731C21.9025 19.2706 22.339 18.7114 22.7083 18.1016C19.227 18.6242 15.6403 17.5154 13.064 14.9394Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'MoonIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>