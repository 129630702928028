<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 22C21.5523 22 22 22.4477 22 23C22 23.5523 21.5523 24 21 24H7C6.44772 24 6 23.5523 6 23C6 22.4477 6.44772 22 7 22H21ZM14 2C14.5523 2 15 2.44772 15 3V16.585L20.2929 11.2929C20.6834 10.9024 21.3166 10.9024 21.7071 11.2929C22.0976 11.6834 22.0976 12.3166 21.7071 12.7071L14.7071 19.7071C14.3166 20.0976 13.6834 20.0976 13.2929 19.7071L6.29289 12.7071C5.90237 12.3166 5.90237 11.6834 6.29289 11.2929C6.68342 10.9024 7.31658 10.9024 7.70711 11.2929L13 16.585V3C13 2.44772 13.4477 2 14 2Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'DoneIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>