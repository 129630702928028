<template>
  <svg :width="size" :height="size" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.83711 4H2.75C2.33579 4 2 4.33579 2 4.75C2 5.16421 2.33579 5.5 2.75 5.5H3.29544L4.20208 14.7477C4.25341 15.2714 4.29613 15.7072 4.35919 16.0622C4.42502 16.4328 4.52185 16.7732 4.71166 17.0901C5.00647 17.5823 5.44066 17.976 5.95928 18.2214C6.29319 18.3794 6.64142 18.4426 7.01669 18.4719C7.37612 18.5 7.814 18.5 8.34018 18.5H11.6599C12.186 18.5 12.6239 18.5 12.9833 18.4719C13.3586 18.4426 13.7068 18.3794 14.0408 18.2214C14.5594 17.976 14.9936 17.5823 15.2884 17.0901C15.4782 16.7732 15.575 16.4328 15.6408 16.0622C15.7039 15.7072 15.7466 15.2714 15.798 14.7477L16.7046 5.5H17.25C17.6642 5.5 18 5.16421 18 4.75C18 4.33579 17.6642 4 17.25 4H13.1629C12.8241 2.56638 11.537 1.5 10 1.5C8.46305 1.5 7.17593 2.56638 6.83711 4ZM8.41825 4H11.5818C11.3009 3.40891 10.6979 3 10 3C9.30207 3 8.6991 3.40891 8.41825 4ZM15.1974 5.5H4.80263L5.69209 14.5725C5.74699 15.1324 5.78449 15.5095 5.83607 15.7999C5.88602 16.0811 5.94015 16.222 5.9985 16.3194C6.14082 16.557 6.35042 16.7471 6.6008 16.8655C6.70344 16.9141 6.84893 16.9542 7.13369 16.9765C7.42768 16.9995 7.80663 17 8.36926 17H11.6308C12.1934 17 12.5724 16.9995 12.8664 16.9765C13.1511 16.9542 13.2966 16.9141 13.3992 16.8655C13.6496 16.7471 13.8592 16.557 14.0015 16.3194C14.0599 16.222 14.114 16.0811 14.164 15.7999C14.2155 15.5095 14.2531 15.1324 14.3079 14.5725L15.1974 5.5ZM12.3122 7.50246C12.725 7.53684 13.0318 7.89934 12.9974 8.31213L12.4974 14.315C12.463 14.7277 12.1005 15.0345 11.6877 15.0001C11.2749 14.9657 10.9682 14.6032 11.0026 14.1905L11.5026 8.18762C11.537 7.77484 11.8995 7.46808 12.3122 7.50246ZM7.68776 7.50247C8.10055 7.46808 8.46305 7.77484 8.49743 8.18762L8.99743 14.1905C9.03181 14.6032 8.72506 14.9657 8.31227 15.0001C7.89949 15.0345 7.53699 14.7277 7.50261 14.315L7.00261 8.31213C6.96823 7.89935 7.27498 7.53685 7.68776 7.50247Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'DeleteMiniIcon',
  props: {
    size: {
      type: Number,
      default: 20
    }
  }
}
</script>