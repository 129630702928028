<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.9525 20.4767C14.2402 22.1084 13.1507 23.6643 11.519 23.9521C9.88731 24.2398 8.33134 23.1503 8.04363 21.5186C7.86042 20.4796 7.70776 19.6138 7.58565 18.9213C4.98296 18.4885 3 16.2258 3 13.4999C3 10.4624 5.46243 7.99995 8.5 7.99995H16.4174L20.3888 5.47145C21.7865 4.58162 23.6408 4.99328 24.5306 6.39091C24.8372 6.87239 25 7.43131 25 8.00209V18.9978C25 20.6547 23.6569 21.9978 22 21.9978C21.4292 21.9978 20.8703 21.835 20.3888 21.5284L16.4174 18.9999L13.692 18.9995C13.7661 19.4195 13.8529 19.9119 13.9525 20.4767ZM10.0132 21.1713C10.1091 21.7152 10.6278 22.0783 11.1717 21.9824C11.7156 21.8865 12.0788 21.3679 11.9829 20.824C11.8579 20.1154 11.7506 19.5069 11.661 18.9985L9.6302 18.9989C9.73503 19.5935 9.86271 20.3176 10.0132 21.1713ZM21.4629 7.15854L17 9.99995V16.9999L21.4629 19.8414C21.6234 19.9435 21.8097 19.9978 22 19.9978C22.5523 19.9978 23 19.5501 23 18.9978V8.00209C23 7.81183 22.9457 7.62552 22.8435 7.46503C22.5469 6.99915 21.9288 6.86193 21.4629 7.15854ZM15 9.99971L8.5 9.99995C6.567 9.99995 5 11.567 5 13.4999C5 15.4329 6.567 16.9999 8.5 16.9999L15 16.9997V9.99971Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'AdvIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>