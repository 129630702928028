<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99128 8C7.99128 4.68629 10.6795 2 13.9957 2C17.3118 2 20 4.68629 20 8V13C20 16.3137 17.3118 19 13.9957 19C10.6795 19 7.99128 16.3137 7.99128 13V8ZM13.9957 4C11.7849 4 9.99274 5.79086 9.99274 8V13C9.99274 15.2091 11.7849 17 13.9957 17C16.2064 17 17.9986 15.2091 17.9986 13V8C17.9986 5.79086 16.2064 4 13.9957 4Z" fill="currentColor"/>
    <path d="M22.8883 17.5865C23.1418 17.0957 22.9492 16.4925 22.458 16.2392C21.9669 15.9859 21.3633 16.1784 21.1098 16.6692C20.4365 17.9727 19.4176 19.0661 18.1644 19.8299C16.9112 20.5937 15.472 20.9985 14.004 21C12.5361 21.0015 11.0961 20.5997 9.84125 19.8386C8.58645 19.0774 7.56523 17.9861 6.88924 16.6841C6.63472 16.1938 6.03069 16.0026 5.54009 16.2569C5.0495 16.5113 4.85812 17.1149 5.11263 17.6051C5.95762 19.2327 7.23415 20.5967 8.80265 21.5482C10.0817 22.3241 11.5146 22.8012 12.9949 22.9499V26C12.9949 26.5523 13.443 27 13.9957 27C14.5484 27 14.9964 26.5523 14.9964 26V22.9499C16.4838 22.8005 17.9232 22.3195 19.2066 21.5373C20.7731 20.5826 22.0468 19.2158 22.8883 17.5865Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'VoiceIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>