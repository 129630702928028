<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14 14.5C17.0376 14.5 19.5 12.0376 19.5 9C19.5 5.96243 17.0376 3.5 14 3.5C10.9624 3.5 8.5 5.96243 8.5 9C8.5 12.0376 10.9624 14.5 14 14.5ZM14 12.5C15.933 12.5 17.5 10.933 17.5 9C17.5 7.067 15.933 5.5 14 5.5C12.067 5.5 10.5 7.067 10.5 9C10.5 10.933 12.067 12.5 14 12.5ZM19 27C22.3137 27 25 24.3137 25 21C25 17.6863 22.3137 15 19 15C15.6863 15 13 17.6863 13 21C13 24.3137 15.6863 27 19 27ZM11.9823 16.5689C12.0858 17.1114 11.7299 17.6351 11.1874 17.7386C8.9152 18.1722 7.91508 19.1005 7.45817 19.7889C7.22045 20.1471 7.10563 20.4763 7.05015 20.7059C7.02241 20.8207 7.00979 20.9095 7.00416 20.9625C7.00144 20.988 7.00037 21.0049 7 21.0121V21.4999C7 21.7761 7.22386 21.9999 7.5 21.9999H10.5C11.0523 21.9999 11.5 22.4476 11.5 22.9999C11.5 23.5522 11.0523 23.9999 10.5 23.9999H7.5C6.11929 23.9999 5 22.8806 5 21.4999V20.9999H6C5 20.9999 5 20.9984 5 20.9984L5 20.9968L5.00002 20.9933L5.00009 20.9855L5.00045 20.966C5.00081 20.9514 5.00143 20.9336 5.00248 20.9126C5.00458 20.8707 5.00842 20.8163 5.01537 20.7509C5.02927 20.6203 5.05572 20.4446 5.1061 20.2361C5.20687 19.8191 5.40455 19.2664 5.79183 18.6829C6.58492 17.488 8.0848 16.2945 10.8126 15.7741C11.3551 15.6706 11.8788 16.0264 11.9823 16.5689ZM21.9362 19.5105L20.2634 19.3463L19.5269 17.6082C19.2963 17.064 18.7054 17.0638 18.4746 17.6081L17.7377 19.3463L16.0652 19.5105C15.4807 19.5679 15.3029 20.1478 15.7542 20.5316L17.0638 21.6452L16.5777 23.4594C16.418 24.0552 16.9154 24.4144 17.4095 24.0572L19.0008 22.9072L20.592 24.0572C21.0882 24.4158 21.5835 24.0551 21.4239 23.4594L20.9377 21.6452L22.2473 20.5316C22.6968 20.1494 22.5183 19.5676 21.9362 19.5105Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'UserCheckIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>